import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useToken } from '../hooks/useToken';
import { routes } from '../utils/constants';

/**
 * Protects routes that require a token
 * @param props the route properties
 * @returns renders the route or redirects the user
 */
export const PrivateRoute = (props: RouteProps) => {
  const { children, ...rest } = props;
  const [token] = useToken();

  return <Route {...rest} render={() => (token ? children : <Redirect to={routes.login.routeProps.path[0]} />)} />;
};
