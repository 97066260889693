export const sampleProfileData = {
  id: '00700000003T2PGAA0',
  firstName: 'Amy',
  lastName: 'Author',
  email: 'amy.author@nyhealth.com',
  phone: '+1-844-692-4692',
  street: '123 test street',
  city: 'testville',
  state: 'NC',
  country: 'US',
  postalCode: '12345',
  timezone: 'GMT',
  pronoun: 'He_Him_His',
  gender: 'Female',
  ORCIDId: '999999',
};

export const sampleProfilePicklistOptions = {
  timezonePicklistOptions: new Map<string, string>([['GMT', 'GMT']]),
  pronounPicklistOptions: new Map<string, string>([['He_Him_His', 'He/Him/His']]),
  genderPicklistOptions: new Map<string, string>([['Male', 'Male']]),
  countryPicklistOptions: new Map<string, string>([['US', 'United States']]),
  statePicklistOptions: new Map<string, string>([['NC', 'North Carolina']]),
};

export const sampleUserPicklistInfos = {
  picklistFieldValues: {
    TimeZoneSidKey: {
      defaultValue: 'GMT',
      values: [
        {
          label: 'GMT',
          value: 'GMT',
        },
      ],
    },
    mvn__PP_Pronoun__c: {
      defaultValue: 'He_Him_His',
      values: [
        {
          label: 'He/Him/His',
          value: 'He_Him_His',
        },
      ],
    },
    mvn__PP_Gender__c: {
      values: [
        {
          label: 'Male',
          value: 'Male',
        },
      ],
    },
    CountryCode: {
      values: [
        {
          label: 'United States',
          value: 'US',
        },
      ],
    },
    StateCode: {
      values: [
        {
          label: 'North Carolina',
          value: 'NC',
        },
      ],
    },
  },
};
