import { Box, Button, Card, CardActions, CardContent, Divider, Link, Typography } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { LabeledList, OverflowTooltip, StatusLabel } from '../';
import { useStyles } from './styles';

type PublicationCardProps = {
  id: string;
  loading: boolean;
  fullyQualifiedName: string;
  productName: string;
  documentNumber: string;
  documentType: string;
  documentSubtype: string;
  targetName: string;
  targetDate: string;
  openTaskCount: number;
  statusColor: string;
  statusLabel: string;
  status: string;
};

export const PublicationCard = (props: PublicationCardProps) => {
  const classes = useStyles();

  const {
    id,
    loading,
    fullyQualifiedName,
    productName,
    documentNumber,
    documentType,
    documentSubtype,
    targetName,
    targetDate,
    openTaskCount,
    statusLabel,
    status,
    statusColor,
  } = props;

  const url = `/publications/${id}`;
  return (
    <Card>
      <CardContent>
        <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
          <OverflowTooltip tooltip={fullyQualifiedName}>
            <Typography className={`${classes.cardTitle} ${classes.cardText}`} variant="h5">
              <Link component={RouterLink} to={url} className={classes.cardTitleAnchor}>
                {fullyQualifiedName}
              </Link>
            </Typography>
          </OverflowTooltip>
          <StatusLabel label={statusLabel} status={status} color={statusColor} />
        </Box>
        <LabeledList
          loading={loading}
          items={[
            { label: 'Product', value: productName },
            { label: 'Document Number', value: documentNumber },
            { label: 'Document Type', value: documentType },
            { label: 'Document Subtype', value: documentSubtype },
            { label: 'Target Name', value: targetName },
            { label: 'Target Date', value: targetDate },
          ]}
        />
      </CardContent>
      <Divider className={classes.cardDivider}></Divider>
      <CardActions>
        <Button
          component={RouterLink}
          to={url}
          endIcon={<ArrowForwardIosIcon />}
          fullWidth
          color="secondary"
          className={classes.actionButton}
        >
          {openTaskCount} Open Task{openTaskCount === 1 ? '' : 's'}
        </Button>
      </CardActions>
    </Card>
  );
};
