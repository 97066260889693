import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardIcon: {
      marginBottom: theme.spacing(1.5),
    },
    cardActions: {
      justifyContent: 'center',
      marginBottom: theme.spacing(1.5),
    },
  })
);
