import { ConnectionOptions } from 'jsforce';
import { CachedSalesforceOrg } from '../typings';

/**
 * Token sessionStorage key name
 */
const TOKEN_KEY = 'token';
const ORGS_KEY = 'orgs';

/**
 * Persist the token to session storage
 * @param userToken OAuth2 token
 */
export const setToken = (userToken: Record<string, string | undefined> | undefined) => {
  if (userToken) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(userToken));
  } else {
    localStorage.removeItem(TOKEN_KEY);
  }
};

/**
 * Returns token from session storage
 * @param userToken OAuth2 token
 * @returns the token
 */
export const getToken = () => {
  const tokenString = localStorage.getItem(TOKEN_KEY) as string;
  return JSON.parse(tokenString) as ConnectionOptions;
};

/**
 * Returns a list of cached orgs
 * @returns the orgs
 */
export const getOrgs = () => {
  const tokenString = localStorage.getItem(ORGS_KEY) as string;
  return (JSON.parse(tokenString) as Array<CachedSalesforceOrg>) || [];
};

/**
 * Adds a new org to the cached orgs
 * @param newOrg org to add
 */
export const addOrg = (newOrg: CachedSalesforceOrg) => {
  let orgs = getOrgs().filter((org) => org.loginUrl !== newOrg.loginUrl);
  orgs.push(newOrg);

  localStorage.setItem(ORGS_KEY, JSON.stringify(orgs));
};

/**
 * Delets an cached og
 * @param key org key to delete
 */
export const removeOrg = (key: number) => {
  const orgs = getOrgs();

  if (key >= orgs.length) {
    throw Error('This org key does not exist.');
  }

  orgs.splice(key, 1);
  localStorage.setItem(ORGS_KEY, JSON.stringify(orgs));
};
