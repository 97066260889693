import { DocumentVersion, PackageFile } from '../typings';
import { client } from './client';

/**
 * Converts a file to a base64 encoded string
 * @param file file to convert
 * @returns the base64 encoded string
 */
export const convertFileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result && (reader.result as string).split(',')[1];
      resolve(result);
    };
    reader.onabort = (error) => reject(error);
    reader.onerror = (error) => reject(error);
  });

/**
 * Formats the Download or Checkout URL details
 * @param version the document version record
 * @returns returns the download details
 */
export const getDownloadDetails = (version: DocumentVersion) => {
  if (!version) {
    return {
      downloadLabel: undefined,
      handleDownload: () => {},
      hasDownloadUrl: false,
      hasCheckoutUrl: false,
    };
  }

  const isOffice365 = version.checkoutType === 'Office365';
  const hasDownloadUrl = version.downloadUrl !== undefined;
  const hasCheckoutUrl = version.checkoutUrl !== undefined;

  let downloadLabel: string;

  if (isOffice365) {
    downloadLabel = hasDownloadUrl ? 'Open in Office 365' : 'Checkout URL unavailable';
  } else {
    downloadLabel = hasDownloadUrl ? 'Download' : 'Download unavailable';
  }

  const handleDownload = async () => {
    const downloadUrl = version.downloadUrl;

    if (downloadUrl) {
      window.location.href = client.getDocumentDownloadUrl(downloadUrl);
    }
  };

  const handleCheckout = async () => {
    const checkoutUrl = version.checkoutUrl;

    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  };

  return { downloadLabel, handleDownload, handleCheckout, hasDownloadUrl, hasCheckoutUrl };
};

/**
 * Formats the Download or Checkout URL details
 * @param version the document version record
 * @returns returns the download details
 */
export const getDownloadAllDetails = (packageFiles: Array<PackageFile>) => {
  if (!packageFiles) {
    return {
      handleDownloadAll: () => {},
    };
  }

  const handleDownloadAll = async () => {
    let zipFileString = '';
    for (let i = 0; i < packageFiles.length; i++) {
      zipFileString += '/' + packageFiles[i].contentDocumentId;
    }
    window.location.href = client.getDocumentAllDownloadUrl(zipFileString);
  };

  return { handleDownloadAll };
};

/**
 * Returns the first letters of a label to form an avatar
 * @param label the label to shorten
 * @returns the shortened label
 */
export const generateAvatarLetters = (label: string) => {
  if (!label) {
    return '?';
  }

  const segments = label
    .replace(/[^A-Za-z0-9 ]/g, '')
    .split(' ')
    .filter((item) => item);

  if (segments.length === 0) {
    return '?';
  } else if (segments.length === 1) {
    return segments[0].charAt(0).toUpperCase();
  } else {
    return (segments[0].charAt(0) + segments[segments.length - 1].charAt(0)).toUpperCase();
  }
};

/**
 * Returns if the string provided is an URL or not
 * @param urlString the potential url string
 * @returns returns if URL or not
 */
export const isValidURL = (urlString: string): string | boolean => {
  const errorMessage = 'It needs to be a valid https:// URL';

  try {
    const url = new URL(urlString);
    return url.protocol === 'https:' || errorMessage;
  } catch (_) {
    return errorMessage;
  }
};
