import { useEffect } from 'react';
import { client } from '../utils/client';
import { ENABLE_MULTIORG } from '../utils/constants';
import { EmptyContainer } from './';

/**
 * Redirects the running user to the authorization url
 * @returns the component
 */
export const RedirectToLogin = () => {
  useEffect(() => {
    if (!ENABLE_MULTIORG) {
      window.location.href = client.getAuthorizationUrl();
    }
  }, []);

  if (!ENABLE_MULTIORG) {
    return <EmptyContainer icon={'login'} message={'Redirecting to login page...'} />;
  } else {
    return (
      <EmptyContainer
        icon={'empty'}
        title={'No default Salesforce Login URL set for this environment.'}
        message={'Please, select an organization manually.'}
      />
    );
  }
};
