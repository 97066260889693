import { Box, Button, Card, CardContent, Collapse, Grid, GridSize, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ReactElement, useState } from 'react';
import { CompleteTaskModal, EmptyContainer } from '../../components';
import { PublicationTask } from '../../typings';

interface IncompleteTaskCardProps {
  type: string;
  subject: string;
  directions?: string;
  dueDate: string;
  onCompleteTaskClick: () => void;
}

/**
 * @description Renders an incomplete Task and displays a button for user to complete
 *
 */
const IncompleteTaskCard = (props: IncompleteTaskCardProps) => {
  const items: Array<{
    header: string;
    size: GridSize;
    value?: string | ReactElement;
    isCompleteTaskButton?: boolean;
  }> = [
    { header: 'Type', value: props.type, size: 2 },
    { header: 'Task Subject', value: props.subject, size: 3 },
    { header: 'Directions', value: props.directions, size: 3 },
    { header: 'Due Date', value: props.dueDate, size: 2 },
    {
      isCompleteTaskButton: true,
      header: '',
      value: (
        <Box>
          <Button onClick={props.onCompleteTaskClick} variant="contained" color="secondary">
            Complete Task
          </Button>
        </Box>
      ),
      size: 2,
    },
  ];

  const content = (
    <Grid container spacing={3}>
      {items.map(({ header, value, size, isCompleteTaskButton }) => {
        return (
          <Grid key={header} item xs={size}>
            {header ? (
              <Typography color="textSecondary" variant="caption">
                {header}
              </Typography>
            ) : null}

            {isCompleteTaskButton ? (
              <Box height="100%" width="100%" display="flex" justifyContent="flex-end" alignItems="center">
                {value}
              </Box>
            ) : (
              <Typography variant="body1">{value}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
  return (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

interface CompletedTaskCardProps {
  type: string;
  subject: string;
  directions?: string;
  dueDate: string;
  comments: string;
}

/**
 * @description Renders a completed Task
 *
 */

const CompletedTaskCard = (props: CompletedTaskCardProps) => {
  const items: Array<{ header: string; size: GridSize; value?: string }> = [
    { header: 'Type', value: props.type, size: 2 },
    { header: 'Task Subject', value: props.subject, size: 3 },
    { header: 'Directions', value: props.directions, size: 3 },
    { header: 'Due Date', value: props.dueDate, size: 2 },
    { header: 'Comments', value: props.comments, size: 2 },
  ];

  const content = (
    <Grid container spacing={3}>
      {items.map(({ header, value, size }) => (
        <Grid key={header} item xs={size}>
          <Typography color="textSecondary" variant="caption">
            {header}
          </Typography>
          <Typography variant="body1">{value}</Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyList: {
      background: 'white',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
    tasksCompleteCardContent: {
      padding: theme.spacing(5),
    },
    tasksCompleteIcon: {
      marginBottom: theme.spacing(1.5),
    },
    incompleteTaskGrid: {
      paddingBottom: theme.spacing(8),
    },
    showIncompleteTasksButton: {
      textDecoration: 'underline',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface PublicationTasksProps {
  loading: boolean;
  tasks: Array<PublicationTask> | null;
  reloadPublication: () => any;
}

/**
 * @description Renders a Publication's complete and incomplete tasks in cards
 *
 */
const PublicationTasks = (props: PublicationTasksProps) => {
  const { tasks, reloadPublication, loading } = props;
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [selectedTaskToComplete, setSelectedTaskToComplete] = useState<PublicationTask | undefined>(undefined);

  const incompleteTasks = tasks?.filter((task) => task.isOpen) ?? [];
  const completedTasks = tasks?.filter((task) => !task.isOpen) ?? [];

  const classes = useStyles();

  const handleTaskComplete = () => {
    reloadPublication();
    setSelectedTaskToComplete(undefined);
  };

  const handleClose = () => {
    setSelectedTaskToComplete(undefined);
  };

  return (
    <Box>
      {!!selectedTaskToComplete && (
        <CompleteTaskModal
          task={selectedTaskToComplete}
          open={true}
          onTaskComplete={handleTaskComplete}
          onClose={handleClose}
        />
      )}
      <Grid classes={{ root: classes.incompleteTaskGrid }} container spacing={3}>
        {incompleteTasks.length ? (
          incompleteTasks.map((task) => {
            const { id, typeLabel, type, subject, directions, dueDate } = task;
            return (
              <Grid xs={12} key={id} item>
                <IncompleteTaskCard
                  type={typeLabel || type}
                  subject={subject}
                  directions={directions}
                  dueDate={dueDate}
                  onCompleteTaskClick={() => setSelectedTaskToComplete(task)}
                />
              </Grid>
            );
          })
        ) : (
          <Grid xs={12} item>
            {!loading && tasks !== null ? <EmptyContainer icon={'check'} message={'All tasks are completed!'} /> : null}
          </Grid>
        )}
      </Grid>
      <Button
        size="large"
        onClick={() => setShowCompleted(!showCompleted)}
        classes={{ root: classes.showIncompleteTasksButton }}
        disableRipple
      >
        {showCompleted ? 'Hide' : 'Show'} Completed Tasks
      </Button>
      <Collapse in={showCompleted}>
        <Grid container spacing={3}>
          {completedTasks.length === 0 && (
            <Grid xs={12} item>
              <EmptyContainer icon={'empty'} message={'Nothing to show.'} />
            </Grid>
          )}
          {completedTasks.map(({ id, typeLabel, subject, directions, dueDate, comments }) => (
            <Grid key={id} xs={12} item>
              <CompletedTaskCard
                type={typeLabel}
                subject={subject}
                directions={directions}
                dueDate={dueDate}
                comments={comments ?? ''}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default PublicationTasks;
