import { Grid } from '@material-ui/core';
import { ConnectionOptions } from 'jsforce';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BackdroppedCircularProgress, EmptyContainer } from '../components';
import { useToken } from '../hooks/useToken';
import { client } from '../utils/client';
import { routes } from '../utils/constants';

export const Callback = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') as string;
  const sfdxCommunityUrl = params.get('sfdc_community_url') as string;

  const history = useHistory();
  const [, setToken] = useToken();
  const [authError, setAuthError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (code) {
      client.updateLoginUrl(sfdxCommunityUrl);

      client
        .authorize(code)
        .then((newToken: ConnectionOptions) => {
          // "instanceUrl" returned by "authorize" doesn't work for uploading docs, so relying on "sfdxCommunityUrl"
          const token = { ...newToken, instanceUrl: sfdxCommunityUrl, loginUrl: sfdxCommunityUrl };
          setToken(token);
          history.replace('/');
        })
        .catch((error) => {
          console.log('An error occured when logging to the Salesforce organization:', error);
          setAuthError(error);
          setToken(undefined);
        });
    }
  }, [code, history]);

  if (authError) {
    return (
      <EmptyContainer
        icon={'error'}
        title={'Login Failed'}
        message={
          'An error occured when logging into the Salesforce organization. Please, reach out to an administrator.'
        }
        actions={[{ label: 'Return to Login', url: routes.login.routeProps.path[0] }]}
      />
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <BackdroppedCircularProgress open={true} />
      </Grid>
    </Grid>
  );
};
