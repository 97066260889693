import { Box, Button, ButtonGroup, Card, CardContent, Grid, GridSize, Typography } from '@material-ui/core';
import { EmptyContainer } from '../../components';
import { PackageFile } from '../../typings';
import { client } from '../../utils/client';
import { ReactElement } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { FileIcon } from '../../../src/components/icons/FileIcon';

interface PackageFileCardProps {
  id: string;
  label: string;
  fileType: string;
  fileExtension: string;
  fileSize: number;
  contentDocumentId: string;
  checkoutUrl?: string;
}

interface PackageFilesProps {
  packageFiles: Array<PackageFile> | null;
}

/**
 * @description Renders a Package File
 *
 */

const PackageFileCard = (props: PackageFileCardProps) => {
  const items: Array<{ header: string; size: GridSize; value?: string | ReactElement }> = [
    { header: 'Title', value: props.label, size: 5 },
    {
      header: 'File Type',
      value: <FileIcon size={36} fileExtension={props.fileExtension} alternateText={props.fileType} />,
      size: 1,
    },
    { header: 'Size', value: props.fileSize.toString(), size: 2 },
  ];

  const handleDownload = async () => {
    const url = client.getDocumentDownloadUrl(props.contentDocumentId);
    window.location.href = url;
  };

  const handleOpenIn365 = async () => {
    window.open(props.checkoutUrl!);
  };

  const content = (
    <Grid container spacing={3}>
      {items.map(({ header, value, size }) => (
        <Grid key={header} item xs={size}>
          <Typography color="textSecondary" variant="caption">
            {header}
          </Typography>
          <Typography variant="body1">{header === 'Size' ? formatBytes(Number(value)) : value}</Typography>
        </Grid>
      ))}
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button
              color="secondary"
              variant="outlined"
              style={{
                maxHeight: '3em',
              }}
              onClick={handleDownload}
            >
              <DownloadIcon fontSize="small"></DownloadIcon>&nbsp;Download
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              style={{
                maxHeight: '3em',
              }}
              onClick={handleOpenIn365}
              disabled={!props.checkoutUrl}
            >
              <FileOpenIcon fontSize="small"></FileOpenIcon>&nbsp;Open in Office 365
            </Button>
          </ButtonGroup>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

/**
 * @description Renders a Publication's package files
 *
 */
const PackageFiles = (props: PackageFilesProps) => {
  const { packageFiles } = props;

  return (
    <Box>
      <Grid container spacing={3}>
        {packageFiles?.length === 0 && (
          <Grid xs={12} item>
            <EmptyContainer icon={'empty'} message={'Nothing to show.'} />
          </Grid>
        )}
        {packageFiles?.map(({ id, label, checkoutUrl, contentDocument }) => (
          <Grid key={id} xs={12} item>
            <PackageFileCard
              id={id}
              label={label}
              fileType={contentDocument.FileType}
              fileExtension={contentDocument.FileExtension}
              fileSize={contentDocument.ContentSize}
              contentDocumentId={contentDocument.Id}
              checkoutUrl={checkoutUrl}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default PackageFiles;
