import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    actionButton: {
      fontFamily: 'DMSans-Medium',
      justifyContent: 'space-between',
      color: '#FFFFFF',
      backgroundColor: '#0050C8',
      whiteSpace: 'nowrap',
    },
    taskPublicationLinkText: {
      color: '#000000',
      textDecoration: 'underline',
    },
  })
);
