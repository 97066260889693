import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils/constants';
import { useStyles } from './styles';

type ActionProps = {
  label: string;
  url: string;
};

type EmptyContainerProps = {
  icon: 'check' | 'empty' | 'login' | 'error';
  title?: string;
  message: string | React.ReactNode;
  actions?: Array<ActionProps>;
};

export const EmptyContainer = (props: EmptyContainerProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleAction = (url: string) => {
    history.push(url);
  };

  useEffect(() => {
    if (typeof props.message === 'string' && props.message.includes('Access Declined')) {
      history.push(routes.logout.routeProps.path[0]);
    }
  }, []);

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.cardContent }}>
        {props.icon === 'error' && <ErrorIcon />}
        {props.icon === 'check' && <CheckIcon />}
        {props.icon === 'empty' && <EmptyIcon />}
        {props.icon === 'login' && <LoginIcon />}
        {props.title && (
          <Typography color="textPrimary">
            <strong>{props.title}</strong>
          </Typography>
        )}
        <Typography color="textSecondary">{props.message}</Typography>
      </CardContent>
      {props.actions && (
        <CardActions classes={{ root: classes.cardActions }}>
          {props.actions.map((action) => (
            <Button color="secondary" variant="contained" onClick={() => handleAction(action.url)}>
              {action.label}
            </Button>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

const ErrorIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.cardIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 24 24"
      fill="none"
      stroke="red"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
};

const CheckIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.cardIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#27AE60"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
      <polyline points="22 4 12 14.01 9 11.01" />
    </svg>
  );
};

const EmptyIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.cardIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
      <line x1="1" y1="1" x2="23" y2="23"></line>
    </svg>
  );
};

const LoginIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.cardIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
      <polyline points="10 17 15 12 10 7"></polyline>
      <line x1="15" y1="12" x2="3" y2="12"></line>
    </svg>
  );
};
