import { useEffect } from 'react';
import { useToken } from '../hooks/useToken';
import { client } from '../utils/client';
import { EmptyContainer } from './';

/**
 * Redirects the running user to the authorization url
 * @returns the component
 */
export const RedirectToLogout = () => {
  const [, setToken] = useToken();

  useEffect(() => {
    handleLogout();
  });

  const handleLogout = async () => {
    let logoutUrl = '/';

    try {
      logoutUrl = await client.logout();
    } catch (e) {
      console.log('error while revoking token', e);
    } finally {
      setToken(undefined);
      window.location.href = logoutUrl;
    }
  };

  return <EmptyContainer icon={'login'} message={'Logging out...'} />;
};
