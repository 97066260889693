import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    selectedItem: {
      color: '#0050C8',
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: '#0050C8',
      borderRadius: 0,
      height: 64,
    },
    unselectedItem: {
      color: '#71899F',
      height: 64,
    },
    komodoProfileButton: {
      color: '#3DBEE2',
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: '#0050C8',
      borderRadius: 0,
      height: 64,
    },
    homeLink: {
      marginRight: theme.spacing(4),
    },
  })
);
