import { Button, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { BackdroppedCircularProgress, CompleteTaskModal, EmptyContainer, EnhancedTable } from '../components';
import { InfiniteScroll } from '../components/InfiniteScroll';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { PublicationTask } from '../typings';
import { routes } from '../utils/constants';
import { useStyles } from './styles';

type Header = {
  label: string;
  field: string;
  apiName?: string;
  sortable: boolean;
};

const defaults = {
  pageSize: 10,
  sortFieldName: 'mvn__PP_Completed_Date__c',
  sortDirection: 'DESC',
};

const tableHeaders: Record<string, Header> = {
  publication: {
    label: 'Publication',
    field: 'publication',
    apiName: 'mvn__CM_Document_Version_Fully_Qualified_Name__c',
    sortable: true,
  },
  documentNumber: {
    label: 'Document #',
    field: 'documentNumber',
    apiName: 'mvn__CM_Document_Version__r.mvn__CM_Document_Number__c',
    sortable: true,
  },
  taskType: {
    label: 'Task Type',
    field: 'taskType',
    apiName: 'mvn__CM_Record_Type_Name__c',
    sortable: true,
  },
  subject: {
    label: 'Subject',
    field: 'subject',
    apiName: 'Subject',
    sortable: true,
  },
  dueDate: {
    label: 'Due Date',
    field: 'dueDate',
    apiName: 'ActivityDate',
    sortable: true,
  },
  completedDate: {
    label: 'Completed Date',
    field: 'completedDate',
    apiName: 'mvn__PP_Completed_Date__c',
    sortable: true,
  },
  actions: {
    label: 'Actions',
    field: 'actions',
    sortable: false,
  },
};

const openTaskTableHeaders = Object.values(tableHeaders).filter((header) => header.field !== 'completedDate');
const closedTaskTableHeaders = Object.values(tableHeaders).filter(
  (header) => header.label !== 'Actions' && header.field !== 'dueDate'
);

const Tasks = () => {
  const classes = useStyles();
  const [selectedTaskToComplete, setSelectedTaskToComplete] = useState<PublicationTask | undefined>(undefined);

  const location = useLocation();
  const showOpenTasks = Boolean(useRouteMatch(routes.tasks.open.routeProps));

  const filters = {
    ...defaults,
    open: showOpenTasks,
  };

  const {
    records,
    hasMore,
    loading,
    error,
    sortDirection,
    sortFieldName,
    setSortFieldName,
    setSortDirection,
    setCursorRequest,
    refresh,
    loadMoreRef,
  } = useInfiniteScroll<PublicationTask>('tasks', filters);

  useEffect(() => {
    setCursorRequest(filters as any);
  }, [location]);

  const tableData =
    records?.map((task) => ({
      key: task.id,
      publication: (
        <>
          {task.publicationReference && (
            <Button href={`/publications/${task.publicationReference.id}`} className={classes.taskPublicationLinkText}>
              {task.publicationReference.fullyQualifiedName}
            </Button>
          )}
        </>
      ),
      documentNumber: task.documentNumber,
      taskType: task.typeLabel,
      subject: task.subject,
      dueDate: task.dueDate,
      completedDate: task.completedDate,
      completedDateTime: task.completedDateTime,
      actions: (
        <Button className={classes.actionButton} variant="contained" onClick={() => setSelectedTaskToComplete(task)}>
          Complete Task
        </Button>
      ),
    })) ?? [];

  const currentTableHeaders = showOpenTasks ? openTaskTableHeaders : closedTaskTableHeaders;

  const handleTaskComplete = () => {
    refresh();
    setSelectedTaskToComplete(undefined);
  };

  const handleClose = () => {
    setSelectedTaskToComplete(undefined);
  };

  const showAllOpenTasksCompleted = !loading && !error && showOpenTasks && tableData.length === 0;
  const showAllClosedTasksCompleted = !loading && !error && !showOpenTasks && tableData.length === 0;
  const showError = !loading && error;
  const showData = !loading && tableData.length !== 0;

  return (
    <Grid container spacing={5}>
      {!!selectedTaskToComplete && (
        <CompleteTaskModal
          task={selectedTaskToComplete}
          open={true}
          onTaskComplete={handleTaskComplete}
          onClose={handleClose}
        />
      )}
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <Typography variant="h4">
          <Switch>
            <Route {...routes.tasks.open.routeProps}>Open Tasks</Route>
            <Route {...routes.tasks.closed.routeProps}>Closed Tasks</Route>
          </Switch>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <BackdroppedCircularProgress open={loading} />
        {showError && <EmptyContainer icon={'error'} title={'Error when rendering Tasks:'} message={error + ''} />}
        {showAllOpenTasksCompleted && <EmptyContainer icon={'check'} message={'All tasks are completed!'} />}
        {showAllClosedTasksCompleted && <EmptyContainer icon={'empty'} message={'Nothing to show.'} />}
        {showData && (
          <>
            <EnhancedTable
              data={tableData}
              headers={currentTableHeaders}
              sortDirection={sortDirection?.toLowerCase() as any}
              sortFieldName={openTaskTableHeaders.find((header) => header.apiName === sortFieldName)?.field as string}
              onSortClick={(field, direction) => {
                setSortFieldName(tableHeaders[field].apiName as string);
                setSortDirection(direction);
              }}
            />
            <InfiniteScroll hasMore={hasMore} loadMoreRef={loadMoreRef} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Tasks;
