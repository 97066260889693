import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        color: '#71899F',
      },
    },
    footerText: {
      color: '#71899F',
    },
  })
);
