import { createContext, useContext, useState } from 'react';
import jsforce from 'jsforce';
import { client } from '../utils/client';
import * as actions from '../utils/persist';
import { getInstanceConfiguration } from '../utils/domain-mapping';

const AuthContext = createContext(undefined as any);

export const useToken = () => useContext(AuthContext);

const AuthProvider = (props: any) => {
  const getToken = (): jsforce.ConnectionOptions => {
    const userToken = actions.getToken();

    if (userToken) {
      client.setToken({ ...getInstanceConfiguration(), ...userToken });
    }

    return userToken;
  };

  const [token, setToken] = useState<jsforce.ConnectionOptions>(getToken());

  const saveToken = (userToken: Record<string, string | undefined>) => {
    actions.setToken(userToken);
    setToken(userToken);
  };

  return <AuthContext.Provider value={[token, saveToken]} {...props}></AuthContext.Provider>;
};

export default AuthProvider;
