import { Box, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppBar, AppFooter } from './components';
import OrgsProvider, { injectLoginUrl } from './hooks/useOrgs';
import AuthProvider from './hooks/useToken';
import { Router } from './Router';
import theme from './utils/theme';

function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    injectLoginUrl(window.location.href);
    setReady(true);
  }, []);

  if (!ready) return <></>;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <SnackbarProvider maxSnack={3}>
            <OrgsProvider>
              <BrowserRouter>
                <CssBaseline />
                <Box display={'flex'} minHeight={'100vh'} flexDirection={'column'} justifyContent={'space-between'}>
                  <Box>
                    <AppBar />
                    <Box>
                      <Box margin="auto" maxWidth="1140px" p={3} pt={9} overflow="none">
                        <Router />
                      </Box>
                    </Box>
                  </Box>
                  <AppFooter />
                </Box>
              </BrowserRouter>
            </OrgsProvider>
          </SnackbarProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
