import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, darken, makeStyles, useTheme } from '@material-ui/core/styles';
import { ReactElement } from 'react';

interface Header {
  field: string;
  label: string;
  sortable: boolean;
}
interface DataItem {
  [key: string]: number | string | ReactElement;
  key: string;
}

export type SortDirection = 'asc' | 'desc';

export interface EnhancedTableProps {
  headers: Array<Header>;
  data: Array<DataItem>;
  sortDirection: SortDirection;
  sortFieldName: string;
  onSortClick: (field: string, direction: SortDirection) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    tableHead: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
    },
    tableHeadCell: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      '&:not(:last-child)': {
        borderRight: `1px solid ${darken('rgba(224, 224, 224, 1)', 0.03)}`,
      },
    },
    tableBodyCell: {
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    tableBodyRow: {
      '&:nth-child(odd)': {
        color: '#000000',
        backgroundColor: theme.palette.background.default,
      },
      '&:nth-child(even)': {
        color: '#000000',
        backgroundColor: darken(theme.palette.background.default, 0.03),
      },
    },
  })
);

/**
 * @description
 * Component for rendering a material ui table enhacned with sortability and custom styles
 *
 * @example
 * <EnhancedTable
 *   headers={[{label: 'Name', field: 'name', sortable: true}]}
 *   data={[{key: 'myKey', name: 'King Pub'}]}
 *   sortDirection="desc"
 *   sortField="name"
 *   onSortClick={(field, direction) => {
 *    this.sortData(field, direction);
 *    this.setField(field);
 *    this.setDirection(direction);
 *   }}
 * />
 *
 */
const EnhancedTable = (props: EnhancedTableProps) => {
  const theme = useTheme();
  const { headers, data, sortFieldName, sortDirection, onSortClick } = props;
  const createSortClickHandler = (field: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = sortFieldName === field && sortDirection === 'desc';
    onSortClick(field, isAsc ? 'asc' : 'desc');
  };

  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                classes={{ root: classes.tableHeadCell }}
                key={header.field}
                align="left"
                sortDirection={header.field === sortFieldName ? sortDirection : false}
              >
                <TableSortLabel
                  disabled={!header.sortable}
                  active={header.field === sortFieldName}
                  hideSortIcon={!header.sortable}
                  direction={header.field === sortFieldName ? sortDirection : 'desc'}
                  onClick={createSortClickHandler(header.field)}
                >
                  <Typography variant="overline" style={{ color: '#000000' }}>
                    {header.label}
                  </Typography>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow classes={{ root: classes.tableBodyRow }} key={row.key}>
              {headers.map((header) => (
                <TableCell
                  key={header.field}
                  classes={{ root: classes.tableBodyCell }}
                  align={header.field === 'actions' ? 'center' : 'left'}
                  width={header.field === 'actions' ? 95 : 'auto'}
                >
                  {row[header.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;
