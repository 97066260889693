import { Box, Button, Container } from '@material-ui/core';
import { useStyles } from './styles';
import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { client } from '../../utils/client';
import { useToken } from '../../hooks/useToken';
export const AppFooter = () => {
  const classes = useStyles();
  const [token] = useToken();
  const [{ value: response, error }, doGetApiVersion] = useAsyncFn(() => client.getApiVersion());
  useEffect(() => {
    if (token) {
      doGetApiVersion();
    }
  }, [token, doGetApiVersion]);
  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.section}>
          <Box className={classes.footerText} width={error ? '40%' : '20%'}>
            &copy; Komodo Health, Inc.
          </Box>
          {!error && response && (
            <Box className={classes.footerText} width={'20%'}>
              {response.mvn__PP_Portal_API_Version__c}
              <div hidden>
                *Current Portal Version: {response.mvn__PP_Portal_API_Version__c}
                *Current API Version of Target Org: {response.mvn__PP_Pubs_API_Version__c}
              </div>
            </Box>
          )}
          {error && <div hidden>Error retrieving api information: {error}</div>}
          <Box width={'60%'} textAlign={'right'}>
            <Button
              href={`https://www.komodohealth.com/privacy-notice`}
              target={'_blank'}
              rel="noreferrer"
              className={classes.footerText}
            >
              Privacy Notice
            </Button>
            <Button
              href={`https://www.komodohealth.com/terms-of-service`}
              target={'_blank'}
              rel="noreferrer"
              className={classes.footerText}
            >
              Terms of Service
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
