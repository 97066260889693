import { Button, Grid, TextField, Theme, Select, MenuItem, InputLabel } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { GetProfilePicklistOptions, GetProfileResponse } from '../../typings';
import { client } from '../../utils/client';

export const labels = {
  fieldRequired: 'This field is required.',
  invalidEmail: 'This needs to be a valid email.',
};

const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    actions: {
      '& > *': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(1),
      },
    },
  })
);

type ProfileFormProps = {
  defaultValues: GetProfileResponse;
  profilePicklistOptions: GetProfilePicklistOptions;
};

export const ProfileForm = (props: ProfileFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState<boolean>(false);
  const classes = useStyles();
  const { register, formState, handleSubmit, reset } = useForm<GetProfileResponse>({
    defaultValues: props.defaultValues,
  });
  const { errors } = formState;

  const onSubmit = async (data: GetProfileResponse) => {
    try {
      await client.updateProfile(data);
      enqueueSnackbar('Profile has been updated.', { variant: 'success' });
      setEditMode(false);
    } catch (error) {
      if (`${error}`.includes('FIELD_INTEGRITY_EXCEPTION')) {
        enqueueSnackbar(
          `A field integrity exception was thrown when updating the profile. Check dependent picklists and validation rules`,
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(`An error occured when updating the profile: ${error}`, { variant: 'error' });
      }
    }
  };

  const onError = (e: any) => console.log('error', e);

  const handleChangePassword = () => {
    window.location.href = client.getChangePassword();
  };

  const createSelectOptionsFromMap = (selectOption: Map<string, string>) => {
    let menuItems = [];
    for (let [key, value] of selectOption) {
      menuItems.push(<MenuItem value={key}>{value}</MenuItem>);
    }
    return menuItems;
  };

  const labelStyle = 'MuiFormLabel-colorSecondary MuiInputLabel-shrink';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="firstname-textfield" id="firstname-label" disabled={!editMode} className={labelStyle}>
              First Name *
            </InputLabel>
            <TextField
              id="firstname-textfield"
              variant="outlined"
              required
              fullWidth
              color={'secondary'}
              {...register('firstName', { required: { value: true, message: labels.fieldRequired } })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="lastname-textfield" id="lastname-label" disabled={!editMode} className={labelStyle}>
              Last Name *
            </InputLabel>
            <TextField
              id="lastname-textfield"
              variant="outlined"
              required
              fullWidth
              color={'secondary'}
              {...register('lastName', { required: { value: true, message: labels.fieldRequired } })}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              htmlFor="emailaddress-textfield"
              id="emailaddress-label"
              disabled={!editMode}
              className={labelStyle}
            >
              Email Address *
            </InputLabel>
            <TextField
              id="emailaddress-textfield"
              variant="outlined"
              required
              type="email"
              fullWidth
              color={'secondary'}
              {...register('email', {
                required: { value: true, message: labels.fieldRequired },
                pattern: {
                  value: EMAIL_REGEX,
                  message: labels.invalidEmail,
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="phone-textfield" id="phone-label" disabled={!editMode} className={labelStyle}>
              Phone Number
            </InputLabel>
            <TextField
              id="phone-textfield"
              variant="outlined"
              type="phone"
              fullWidth
              color={'secondary'}
              {...register('phone')}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="street-textfield" id="street-label" disabled={!editMode} className={labelStyle}>
              Street
            </InputLabel>
            <TextField
              id="street-textfield"
              variant="outlined"
              type="street"
              fullWidth
              color={'secondary'}
              {...register('street')}
              error={!!errors.street}
              helperText={errors.street?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="city-textfield" id="city-label" disabled={!editMode} className={labelStyle}>
              City
            </InputLabel>
            <TextField
              id="city-textfield"
              variant="outlined"
              type="city"
              fullWidth
              color={'secondary'}
              {...register('city')}
              error={!!errors.city}
              helperText={errors.city?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="state-select" id="state-label" disabled={!editMode} className={labelStyle}>
              State
            </InputLabel>
            <Select
              variant="outlined"
              id="state-select"
              defaultValue={props.defaultValues?.state ? props.defaultValues.state : ''}
              labelId="state-label"
              {...register('state')}
              fullWidth
              disabled={!editMode}
            >
              <MenuItem value={''}>--None--</MenuItem>
              {createSelectOptionsFromMap(props.profilePicklistOptions.statePicklistOptions)}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="country-select" id="country-label" disabled={!editMode} className={labelStyle}>
              Country
            </InputLabel>
            <Select
              variant="outlined"
              id="country-select"
              defaultValue={props.defaultValues?.country ? props.defaultValues.country : ''}
              labelId="country-label"
              {...register('country')}
              fullWidth
              disabled={!editMode}
            >
              <MenuItem value={''}>--None--</MenuItem>
              {createSelectOptionsFromMap(props.profilePicklistOptions.countryPicklistOptions)}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              htmlFor="postalcode-textfield"
              id="postalcode-label"
              disabled={!editMode}
              className={labelStyle}
            >
              Postal Code
            </InputLabel>
            <TextField
              id="postalcode-textfield"
              variant="outlined"
              type="postalCode"
              fullWidth
              color={'secondary'}
              {...register('postalCode')}
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="timezone-select" id="timezone-label" disabled={!editMode} className={labelStyle}>
              Timezone
            </InputLabel>
            <Select
              variant="outlined"
              id="timezone-select"
              defaultValue={props.defaultValues?.timezone ? props.defaultValues.timezone : ''}
              labelId="timezone-label"
              {...register('timezone')}
              disabled={!editMode}
              fullWidth
            >
              {createSelectOptionsFromMap(props.profilePicklistOptions.timezonePicklistOptions)}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="pronoun-select" id="pronoun-label" disabled={!editMode} className={labelStyle}>
              Preferred Pronouns
            </InputLabel>
            <Select
              variant="outlined"
              id="pronoun-select"
              defaultValue={props.defaultValues?.pronoun ? props.defaultValues.pronoun : ''}
              labelId="pronoun-label"
              {...register('pronoun')}
              disabled={!editMode}
              fullWidth
            >
              <MenuItem value={''}>--None--</MenuItem>
              {createSelectOptionsFromMap(props.profilePicklistOptions.pronounPicklistOptions)}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="gender-select" id="gender-label" disabled={!editMode} className={labelStyle}>
              Gender
            </InputLabel>
            <Select
              variant="outlined"
              id="gender-select"
              defaultValue={props.defaultValues?.gender ? props.defaultValues.gender : ''}
              labelId="gender-label"
              {...register('gender')}
              disabled={!editMode}
              fullWidth
            >
              <MenuItem value={''}>--None--</MenuItem>
              {createSelectOptionsFromMap(props.profilePicklistOptions.genderPicklistOptions)}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="orcidid-textfield" id="orcidid-label" disabled={!editMode} className={labelStyle}>
              ORCID Id
            </InputLabel>
            <TextField
              id="orcidid-textfield"
              variant="outlined"
              type="ORCIDId"
              fullWidth
              color={'secondary'}
              {...register('ORCIDId')}
              error={!!errors.ORCIDId}
              helperText={errors.ORCIDId?.message}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        <div className={classes.actions} style={{ float: 'right' }}>
          <Button
            size="large"
            onClick={handleChangePassword}
            style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#0000EE' }}
          >
            Change Password
          </Button>
          {!editMode && (
            <Button size="large" variant="outlined" onClick={() => setEditMode(true)}>
              Edit Profile
            </Button>
          )}
          {editMode && (
            <>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  reset();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
              <Button size="large" color="secondary" variant="contained" type="submit">
                Save Profile
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  );
};
