import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  secondaryTail: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  timelineContentGrid: {
    marginTop: theme.spacing(-0.75),
    marginBottom: theme.spacing(1.75),
  },
  timelineDot: {
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(0.75),
  },
  // hide left side since we only display content on right
  timelineItemMissingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
  timelineContentGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  comment: {
    borderLeft: `2px solid ${alpha(theme.palette.secondary.main, 0.45)}`,
    paddingLeft: theme.spacing(2),
  },
  editIconWrapper: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
    display: 'flex',
    width: '26px',
    height: '26px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
  },
  divider: {
    height: '2px',
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(3.5),
  },
  versionTitleText: {
    fontFamily: 'DMSans-Medium',
  },
  revisionTitleText: {
    fontFamily: 'DMSans-Medium',
    marginTop: theme.spacing(0.25),
  },
  uploadedFileWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    border: '1px solid #e9e9e9',
    borderRadius: '2px',
  },
}));
