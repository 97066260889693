import { createContext, useContext, useState } from 'react';
import { CachedSalesforceOrg } from '../typings';
import * as actions from '../utils/persist';

type OrgsContextType = [Array<CachedSalesforceOrg>, (org: CachedSalesforceOrg) => void, (key: number) => void];

export const OrgsContext = createContext<OrgsContextType>([[], () => {}, () => {}]);

export const useOrgs = () => useContext(OrgsContext);

/**
 * Parses the URL and injects if an org is found
 * @param url the url to parse
 */
export const injectLoginUrl = (url: string) => {
  const searchParams = new URL(url).searchParams;

  const label = searchParams.get('salesforceInstanceLabel');
  const loginUrl = searchParams.get('salesforceInstanceUrl');

  if (label && loginUrl) {
    actions.addOrg({ label, loginUrl });
  }
};

const OrgsProvider = (props: any) => {
  const getOrgs = (): Array<CachedSalesforceOrg> => {
    return actions.getOrgs();
  };

  const [orgs, setOrgs] = useState<Array<CachedSalesforceOrg>>(getOrgs());

  const addOrg = (org: CachedSalesforceOrg) => {
    actions.addOrg(org);
    setOrgs(getOrgs());
  };

  const removeOrg = (key: number) => {
    actions.removeOrg(key);
    setOrgs(getOrgs());
  };

  return <OrgsContext.Provider value={[orgs, addOrg, removeOrg]} {...props}></OrgsContext.Provider>;
};

export default OrgsProvider;
